import commonApiService from "./commonApiService.js";
import {getTokenHeader} from "../helpers/accessTokenHelper.js";

const createComment = async (data) => {
    try {
        const response = await commonApiService.apiPrivate().post('/comments', data, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al enviar el comentario');
    }
};

const getComments = async (taskId) => {
    try {
        const response = await commonApiService.apiPrivate().get(`/comments/${taskId}`, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al enviar el comentario');
    }
};

const uploadFile = async (projectId, taskId, commentId, file) => {
    try {
        const formData = new FormData();
        formData.append("project_id", projectId);
        formData.append("task_id", taskId);
        formData.append("comment_id", commentId);
        formData.append("file", file);

        const response = await commonApiService.apiPrivate().post('/comments/upload-file', formData, {
            headers: {
                ...getTokenHeader().headers,
                'Content-Type': 'multipart/form-data',
            }
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al subir el archivo');
    }
};

const commentsService = {
    createComment,
    getComments,
    uploadFile,
}

export default commentsService;