import React, {useEffect, useState} from "react";
import SidebarNav from "../Sidebar/SidebarNav.js";
import {useNavigate, useParams} from 'react-router-dom';
import ModalTask from "./ModalTask.js";
import projectsService from "../../../services/projectsService.js";
import activitiesService from "../../../services/activitiesService.js";
import TasksPartialRowItems from "./TasksPartialRowItems.js";
import LoadingContent from "../../public/LoadingContent.js";

const Tasks = () => {

    const {projectId} = useParams();
    const titleSingular = 'Tarea';
    const titlePlural = 'Tareas';
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [dataProjects, setDataProjects] = useState([]);
    const [currentProjectName, setCurrentProjectName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingModal, setEditingModal] = useState(null);
    const [parentTaskId, setParentTaskId] = useState("");
    const [expandedTasks, setExpandedTasks] = useState({});
    const [loading, setLoading] = useState(true);

    const loadContent = () => {
        setLoading(true);
        activitiesService.getActivities(projectId).then(data => {
            data = RestructureTasks(data);
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    if(data[i].project.id === projectId) {
                        setCurrentProjectName(data[i].project.project_name);
                        break;
                    }
                }
            }
            setData(data);
            setLoading(false);
        });
    }

    const RestructureTasks = (tasks) => {
        const tasksById = {};

        tasks.forEach(task => {
            tasksById[task.id] = { ...task, subtasks: [] };
        });

        const rootTasks = [];

        tasks.forEach(task => {
            if (task.parent_task_id) {
                const parentTask = tasksById[task.parent_task_id];
                if (parentTask) {
                    parentTask.subtasks.push(tasksById[task.id]);
                }
            } else {
                rootTasks.push(tasksById[task.id]);
            }
        });

        return rootTasks;
    }

    const loadProjects = () => {
        projectsService.getProjects().then(data => {
            if (data && data.length > 0) {
                setDataProjects(data);
            }
        });
    }

    const goToActivity = (id) => {
        navigate(`/actividad/${id}`);
    }

    const handleNewActivity = () => {
        setEditingModal(null);
        setIsModalOpen(true);
    }

    const handleOpenModalForEdit = (data) => {
        setEditingModal(data);
        setIsModalOpen(true);
    }

    const handleDelete = (id) => {
        if(window.confirm("¿Estás seguro que deseas eliminar esta tarea?")){
            activitiesService.deleteActivity(id).then(data => {
                loadContent();
            });
        }
    }

    const toggleExpand = (id) => {
        setExpandedTasks(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    useEffect(() => {
        loadContent();
        loadProjects();
    }, []);

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                <SidebarNav/>

                {isModalOpen && (
                    <ModalTask
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        editingModal={editingModal}
                        loadContent={loadContent}
                        titleSingular={titleSingular}
                        dataProjects={dataProjects}
                        parentTaskId={parentTaskId}
                        projectId={projectId}
                        />
                )}

                <div className="col-md-9 col-lg-10 p-4 content-container">

                    {loading && (
                        <LoadingContent/>
                    )}

                    {!loading && (
                        <div>
                            <h3 className="mb-4">
                                <i className="bi bi-list-task"></i>&nbsp;
                                {currentProjectName && (
                                    <>
                                        {currentProjectName}
                                        <span> > </span>
                                    </>
                                )}
                                {titlePlural}
                            </h3>

                            <div className="btn-group" role="group">
                                <button className="btn btn-secondary mb-3"
                                        onClick={() => navigate(-1)}>
                                    <i className="bi bi-arrow-left"></i> Atrás
                                </button>

                                <button className="btn btn-success mb-3" onClick={handleNewActivity}>
                                    Crear nueva {titleSingular}
                                </button>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="p-3 bg-light rounded shadow-sm">

                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th>Creado</th>
                                                <th>Nombre</th>
                                                <th>Estado</th>
                                                <th>Fecha estimada de Inicio</th>
                                                <th>Fecha estimada de Término</th>
                                                <th>Creado por</th>
                                                <th>Asignados</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {Array.isArray(data) && data.map((item) => {

                                                let isExpired = false;
                                                if (item.estimated_end != null){
                                                    const estimatedEndDate = new Date(item.estimated_end);
                                                    const today = new Date();
                                                    isExpired = estimatedEndDate < today;
                                                }

                                                return (
                                                    <TasksPartialRowItems
                                                        key={item.id}
                                                        idented={false}
                                                        item={item}
                                                        isExpired={isExpired}
                                                        toggleExpand={toggleExpand}
                                                        expandedTasks={expandedTasks}
                                                        goToActivity={goToActivity}
                                                        handleOpenModalForEdit={handleOpenModalForEdit}
                                                        handleDelete={handleDelete}
                                                    />
                                                );
                                            })}


                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>

            </div>
        </div>
    );
}

export default Tasks;