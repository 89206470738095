import React from 'react';

function SuccessContent({title, message}) {
    return (
        <div className="login-container d-flex align-items-center justify-content-center">
            <div className="card shadow-lg p-4">
                <div className="card-body">
                    <img src="/logo-rcj.png" className="logo-login-top" alt="Red Ciudad Joven"/>
                    <div className="alert alert-dismissible alert-success" style={{marginTop: '30px', marginBottom: '30px'}}>
                        <h4 className="alert-heading">{title}</h4>
                        <p className="mb-0">{message}</p>
                    </div>
                    <div className="text-center">
                        <img src="/logo-santiago.png" className="logo-login-bottom" alt="Red Ciudad Joven"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessContent;