import React, {useEffect, useState} from "react";
import SidebarNav from "../Sidebar/SidebarNav.js";
import customDataService from "../../../services/customDataService.js";
import utilsHelper from "../../../helpers/utilsHelper.js";
import {useNavigate} from "react-router-dom";
import ModalCustomFieldGroup from "./ModalCustomFieldGroup.js";
import LoadingContent from "../../public/LoadingContent.js";

const ListCustomFieldsGroups = () => {

    const titleSingular = 'grupo campo personalizado';
    const titlePlural = 'Campos personalizados';

    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingModal, setEditingModal] = useState(null);
    const navigateTo = useNavigate();
    const [loading, setLoading] = useState(true);

    const loadingContent = () => {
        setLoading(true);
        customDataService.getCustomDataGroups().then(data => {
            setData(data);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadingContent();
    },[])

    const handleCreate = () => {
        setEditingModal(null);
        setIsModalOpen(true);
    };

    const handleEdit = (data) => {
        setEditingModal(data);
        setIsModalOpen(true);
    };

    const handleDelete = async (itemId) => {
        if(window.confirm("¿Seguro que deseas eliminar este grupo?")) {
            await customDataService.deleteCustomDataGroups(itemId);
            loadingContent()
        }
    };

    const handleCustomFields = (item) => {
        navigateTo('/datos-personalizados/' + item.id, {
            state: item
        });
    };

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                {isModalOpen && (
                    <ModalCustomFieldGroup
                        onClose={() => setIsModalOpen(false)}
                        isModalOpen={isModalOpen}
                        editingModal={editingModal}
                        loadingContent={loadingContent}
                        titleSingular={titleSingular}
                    />
                )}

                <SidebarNav/>

                <div className="p-4 content-container col-md-9 col-lg-10">

                    {loading && (
                        <LoadingContent/>
                    )}

                    {!loading && (
                        <div>
                            <h3 className="mb-4">
                                <i className="bi bi-ui-checks-grid"></i>  Administración de Grupos de {titlePlural}
                            </h3>
                            <button className="btn btn-primary mb-3" onClick={handleCreate}>
                                Crear Nuevo Grupo
                            </button>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Fecha de creación</th>
                                    <th>Fecha de actualización</th>
                                    <th>Proyectos</th>
                                    <th>Tareas</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.group_name}</td>
                                        <td>{utilsHelper.formatDate(item.created_at, true)}</td>
                                        <td>{utilsHelper.formatDate(item.updated_at, true)}</td>
                                        <td>{item.is_project_joined ? 'Sí': 'No'}</td>
                                        <td>{item.is_task_joined ? 'Sí': 'No'}</td>
                                        <td>
                                            <button
                                                className="btn btn-success btn-sm me-2"
                                                onClick={() => handleCustomFields(item)}>
                                                Datos Personalizados
                                            </button>
                                            <button
                                                className="btn btn-warning btn-sm me-2"
                                                onClick={() => handleEdit(item)}>
                                                Editar
                                            </button>
                                            <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() => handleDelete(item.id)}>
                                                Borrar
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
}

export default ListCustomFieldsGroups;