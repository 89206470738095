import React, {useEffect, useState} from "react";
import SidebarNav from "../Sidebar/SidebarNav.js";
import {useNavigate, useParams} from "react-router-dom";
import activitiesService from "../../../services/activitiesService.js";
import utilsHelper from "../../../helpers/utilsHelper.js";
import statusService from "../../../services/statusService.js";
import Comments from "./Comments.js";
import UsersAssign from "./UsersAssign.js";
import customDataService from "../../../services/customDataService.js";
import ModalTask from "./ModalTask.js";
import projectsService from "../../../services/projectsService.js";
import customDataValuesService from "../../../services/customDataValuesService.js";
import MultiSelectCheckbox from "../Components/MultiSelectCheckbox.js";
import SimpleSelectCheckbox from "../Components/SimpleSelectCheckbox.js";
import MapEditor from "../Components/MapEditor.js";

const Task = () => {

    const {taskId} = useParams();
    const navigate = useNavigate();
    const [dataTask, setDataTask] = useState({});
    const [workHours, setWorkHours] = useState("");
    const [workHoursTotal, setWorkHoursTotal] = useState(0);
    const [dataStatuses, setDataStatus] = useState({});
    const [dataCustom, setDataCustom] = useState([]);
    const [dataProjects, setDataProjects] = useState([]);
    const [parentTaskId, setParentTaskId] = useState("");
    const [editingModal, setEditingModal] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showUsersAssign, setShowUsersAssign] = useState(false);
    const prioridades = [
        { id: 1, name: "Muy Alta", selected: false, color : "#e74c3c" },
        { id: 2, name: "Alta", selected: false, color : "#f39c12" },
        { id: 3, name: "Normal", selected: true, color : "#2ecc71" },
        { id: 4, name: "Baja", selected: false, color : "#7f8c8d" }
    ];
    const [isPriorityOpen, setPriorityOpen] = useState(false);
    const [selectedPriority, setSelectedPriority] = useState(
        { id: 3, name: "Normal", selected: true, color : "#2ecc71" }
    );

    const addUserToActivity = async (user) => {
        await activitiesService.addUserToActivity(taskId, user.id);
        getActivity();
    }

    const toggleDropdown = () => setIsOpen(!isOpen);

    const toggleDropdownPriority = () => setPriorityOpen(!isPriorityOpen);

    const handleOptionClick = async (status) => {
        if(window.confirm(`¿Deseas cambiar el estado de esta tarea a "${status.name}"?`)){
            setSelectedStatus(status);
            await activitiesService.updateActivity(taskId, {
                status_id: status.id,
            });
        }
        setIsOpen(false);
    };

    const handleOptionPriorityClick = async (priority) => {
        if(window.confirm(`¿Deseas cambiar la prioridad de esta tarea a "${priority.name}"?`)){
            setSelectedPriority(priority);
            await activitiesService.updateActivity(taskId, {
                priority: priority.id,
            });
        }
        setPriorityOpen(false);
    };

    const handleChangeWorkHours = (e) => {
        const { value } = e.target;
        setWorkHours(value)
    }

    const handleUpdateWorkHours = async () => {
        if(workHours > 0 && window.confirm(`¿Deseas agregar ${workHours} horas de trabajo a esta tarea?`)){
            await activitiesService.addTaskWorkHours({
                task_id: taskId,
                hours: parseInt(workHours),
            });
            setWorkHours("");
        }
    }

    const handleChange = (e, record) => {
        const { name, value } = e.target;

        const updatedDataCustom = dataCustom.map((group) => {
            if (record.custom_data_group_id === group.groupID) {
                return {
                    ...group,
                    data: group.data.map((item) => {
                        if (item.id === name) {
                            return {
                                ...item,
                                value: value,
                            };
                        }
                        return item;
                    }),
                };
            }
            return group;
        });

        setDataCustom(updatedDataCustom);
    };

    const getActivity = async () => {
        const activityResponse = await activitiesService.getActivity(taskId);
        if (activityResponse.success) {
            setDataTask(activityResponse.data);
        }
    }

    const removeUserFromActivity = (userId) => {
        activitiesService.removeUserToActivity(taskId, userId).then(() => {
            getActivity();
        });
    }

    const handleCreateSubTask = () => {
        setParentTaskId(taskId);
        setIsModalOpen(true)
    }

    const loadProjects = () => {
        projectsService.getProjects().then(data => {
            if (data && data.length > 0) {
                setDataProjects(data);
            }
        });
    }

    const loadStatuses = () => {
        statusService.getStatuses().then(statuses => {
            if (statuses.success) {
                setDataStatus(statuses.data);
            }
        });
    }

    const loadTask = (taskId) => {
        activitiesService.getActivity(taskId).then(activities => {
            if(activities.success) {
                setDataTask(activities.data);
                prioridades.forEach((prioridad) => {
                    if (prioridad.id === activities.data.priority) {
                        setSelectedPriority(prioridad);
                    }
                });
            }
        });
    }

    const loadWorkHours = (taskId) => {
        activitiesService.getTaskWorkHours(taskId).then(results => {
            if(results && results.length > 0) {
                let sumHours = 0;
                results.forEach(result => {
                    sumHours += result.hours;
                });
                setWorkHoursTotal(sumHours);
            }
        });
    }

    const loadCustomDataGroups = (taskId) => {
        customDataService.getCustomDataGroupsForTasks().then(customDataGroups => {
            if(customDataGroups.length > 0) {
                customDataGroups.forEach(group => {
                    customDataService.getCustomDataFields(group.id, taskId).then(fields => {
                        if(fields.length > 0) {
                            setDataCustom((prevData) => {
                                fields.forEach(field => {
                                    try {
                                        field.option_list = JSON.parse(field.option_list);
                                    } catch(e) {}
                                });
                                const newData = {
                                    groupID: group.id,
                                    groupName: group.group_name,
                                    data: fields,
                                };

                                const isDuplicate = prevData.some(
                                    (item) => item.groupID === newData.groupID && item.groupName === newData.groupName
                                );

                                return isDuplicate ? prevData : [...prevData, newData];
                            });
                        }
                    });
                });
            }
        });
    }

    const handleSave = () => {
        if(Array.isArray(dataCustom)){
            const arrayToSave = [];
            dataCustom.map((item) => {
                if(Array.isArray(item.data)){
                    item.data.map((dataField) => {
                        if(typeof dataField.value !== "undefined") {
                            var dataValue = dataField.value;
                            if(Array.isArray(dataValue)){
                                dataValue = JSON.stringify(dataValue);
                            }
                            arrayToSave.push({
                                task_id: taskId,
                                custom_data_group_id: dataField.custom_data_group_id,
                                custom_data_id: dataField.id,
                                value: dataValue,
                            });
                        }
                    });
                }
            });
            if (arrayToSave.length > 0) {
                console.log(arrayToSave);
                customDataValuesService.saveCustomDataValues(arrayToSave);
            }
        }
    }

    const handleChangeMapValue = (record) => {
        /*
        //TODO, ESTO DA ERROR, AVERIGUAR PORQUE
        setDataCustom((prevDataCustom) => {
            const index = prevDataCustom.findIndex(item => item.id === record.id);
            if (index !== -1) {
                const updatedDataCustom = [...prevDataCustom];
                updatedDataCustom[index] = record;
                return updatedDataCustom;
            } else {
                return [...prevDataCustom, record];
            }
        });
        */
    }

    const loadContent = () => {
        //TODO: actualizar
    }

    useEffect( () => {
        loadProjects();
        loadStatuses();
        loadTask(taskId);
        loadCustomDataGroups(taskId);
        loadWorkHours(taskId);
    }, [taskId]);

    useEffect(() => {
        if (dataStatuses.length > 0 && dataTask.id) {
            dataStatuses.forEach((item) => {
                if(item.id === dataTask.status_id) {
                    setSelectedStatus(item);
                }
            });
        }
        if (dataCustom.length > 0) {
            //TODO: Ver si se tiene que hacer algo con el el dataCustom cargado
        }
    }, [dataStatuses, dataTask, dataCustom]);

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                <SidebarNav/>

                {isModalOpen && (
                    <ModalTask
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        titleSingular="Subtarea"
                        editingModal={editingModal}
                        loadContent={loadContent}
                        dataProjects={dataProjects}
                        parentTask={dataTask}
                    />
                )}

                <div className="col-md-9 col-lg-10 p-4 content-container">
                    <h3 className="mb-4">{dataTask.name}</h3>

                    {(() => {
                        const estimatedEndDate = new Date(dataTask.estimated_end);
                        const today = new Date();
                        const timeDiff = today - estimatedEndDate;
                        const daysDelayed = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                        const isExpired = estimatedEndDate < today;

                        return isExpired ? (
                            <div className="alert alert-dismissible alert-danger expired-row">
                                <strong>¡Atención!</strong> Esta tarea se encuentra atrasada {daysDelayed} {daysDelayed === 1 ? "día" : "días"}.
                            </div>
                        ) : null;
                    })()}



                    <div className="btn-group">
                        <button className="btn btn-secondary mb-3"
                                onClick={() => navigate(-1)}>
                            <i className="bi bi-arrow-left"></i> Atrás
                        </button>

                        <button className="btn btn-success mb-3"
                                onClick={handleCreateSubTask}>
                            Crear Subtarea
                        </button>

                        <button className="btn btn-primary mb-3" onClick={handleSave}>
                            Guardar
                        </button>
                    </div>

                    <div className="row">
                        <div className="col-md-4 row height-min-content">

                            {/* ESTADO Y DESCRIPCION DE LA TAREA */}
                            <div className="card-box">

                                <div className="task-status-container">
                                    <div className="task-select">
                                        <label htmlFor="status-selector">Estado:</label>
                                        <div className="custom-select">
                                            <div
                                                className="select-header"
                                                onClick={toggleDropdown}
                                                style={{
                                                    backgroundColor: selectedStatus?.color || '#f0f0f0',
                                                    color: selectedStatus?.color ? '#fff' : '#000',
                                                }}
                                            >
                                                {selectedStatus?.name || '---'}
                                            </div>
                                            {isOpen && (
                                                <ul className="select-options">
                                                    {Array.isArray(dataStatuses) &&
                                                        dataStatuses.map((status) => (
                                                            <li
                                                                key={status.id}
                                                                onClick={() => handleOptionClick(status)}
                                                                style={{
                                                                    backgroundColor: status.color,
                                                                    color: '#fff',
                                                                }}>
                                                                {status.name}
                                                            </li>
                                                        ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>

                                    <div className="task-select">
                                        <label htmlFor="task-select">Prioridad</label>
                                        <div className="custom-select">
                                            <div
                                                className="select-header"
                                                onClick={toggleDropdownPriority}
                                                style={{
                                                    backgroundColor: selectedPriority?.color || '#f0f0f0',
                                                    color: selectedPriority?.color ? '#fff' : '#000',
                                                }}
                                            >
                                                {selectedPriority?.name || '---'}
                                            </div>
                                            {isPriorityOpen && (
                                                <ul className="select-options">
                                                    {prioridades.map(value => (
                                                        <li
                                                            key={value.id}
                                                            onClick={() => handleOptionPriorityClick(value)}
                                                            style={{
                                                                backgroundColor: value.color,
                                                                color: '#fff',
                                                            }}>
                                                            {value.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>

                                    <div className="task-hours">
                                        {workHoursTotal > 0 ? (
                                            <label htmlFor="work-hours">
                                                <b>
                                                    Total de Horas de trabajo realizadas: <br/>
                                                    <h3 style={{display: 'inline'}}>{workHoursTotal}</h3>
                                                    <button style={{marginLeft:'10px',marginTop: '-13px'}} className="btn btn-primary btn-xsmall">
                                                        <i className="bi bi-clock"></i>
                                                    </button>
                                                </b>
                                            </label>
                                        ):null}


                                        <label htmlFor="work-hours">
                                            Agregar horas de trabajo:
                                        </label>

                                        <div className="btn-group">
                                            <input style={{height: '40px'}} type="number" id="work-hours" min="0" step="0.1"
                                                   onChange={handleChangeWorkHours}
                                                   value={workHours}
                                                   placeholder="Agregar horas aquí"/>
                                            <button onClick={handleUpdateWorkHours} className="btn btn-success mb-3">
                                                <i className="bi bi-check-circle-fill"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <textarea
                                        readOnly className="form-control form-control-lg" rows="5"
                                        value={dataTask.description}></textarea>
                                </div>

                            </div>

                            {/* TAREA CREADA POR */}
                            <div className="card-box">
                                <small>Creado por:</small>
                                <div className="profile">
                                    <div className="profile-avatar profile-avatar-reportado">
                                        {utilsHelper.shortenName(dataTask.created_by_user?.name)}
                                    </div>
                                    <div className="profile-info">
                                        <p><strong>{dataTask.created_by_user?.name}</strong></p>
                                    </div>
                                </div>
                            </div>

                            {/* TAREA ASIGNADA A */}
                            <div className="card-box">
                                <small>Asignado a:</small>

                                {!showUsersAssign && (
                                    <div className="button-assign-users-container">
                                        <button className="round-button" onClick={() => setShowUsersAssign(true)}>
                                            <i className="bi bi-person"></i>
                                        </button>
                                    </div>
                                )}

                                {showUsersAssign && (
                                    <UsersAssign
                                        setShowUsersAssign={setShowUsersAssign}
                                        addUserToActivity={addUserToActivity}/>
                                )}

                                <div style={{paddingTop: '20px'}}></div>

                                {Array.isArray(dataTask.task_assigns) && dataTask.task_assigns.map((task_assign) => (
                                    <div className="profile user-assigned-item" key={task_assign.id}>
                                        <div className="button-assign-users-container">
                                            <button className="round-button round-close-button"
                                                    onClick={() => removeUserFromActivity(task_assign.id)}>
                                                <i className="bi bi-x"></i>
                                            </button>
                                        </div>
                                        <div className="profile-avatar profile-avatar-asignado">
                                            {utilsHelper.shortenName(task_assign.name)}
                                        </div>
                                        <div className="profile-info">
                                            <p><strong>{task_assign.name}</strong></p>
                                        </div>
                                    </div>
                                ))}

                            </div>

                            {/* FECHAS DE LA TAREA */}
                            <div className="dates-container card-box">
                                <div className="date-item">
                                    <span className="date-label">Inicio Estimado:</span>
                                    <span
                                        className="date-value">{utilsHelper.formatDate(dataTask.estimated_start, false)}</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Fin Estimado:</span>
                                    <span
                                        className="date-value">{utilsHelper.formatDate(dataTask.estimated_end, false)}</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Inicio Real:</span>
                                    <span
                                        className="date-value">{utilsHelper.formatDate(dataTask.actual_start, false)}</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Fin Real:</span>
                                    <span
                                        className="date-value">{utilsHelper.formatDate(dataTask.actual_end, false)}</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Creado el:</span>
                                    <span
                                        className="date-value">{utilsHelper.formatDate(dataTask.created_at, true)}</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Actualizado el:</span>
                                    <span
                                        className="date-value">{utilsHelper.formatDate(dataTask.updated_at, true)}</span>
                                </div>
                            </div>

                        </div>


                        <div className="col-md-4">
                            {Array.isArray(dataCustom) && dataCustom.map((item, index) => (
                                <div key={`item-${index}`} className="dates-container card-box">
                                    <h6>{item.groupName}</h6>
                                    {Array.isArray(item.data) && item.data.map((record, recordIndex) => (
                                        <div key={`record-${recordIndex}`} className="form-group">
                                            {record.field_type !== 'map_editor' ? (
                                                <span className="date-label">{record.field_name}</span>
                                            ): null}
                                            {
                                                record.field_type === 'textarea' ? (

                                                    <textarea name={record.id}
                                                              onChange={(e) => handleChange(e, record)}
                                                              value={record.value}
                                                              className="form-control date-value"
                                                              required={record.mandatory}></textarea>

                                                ) : record.field_type === 'select' ? (
                                                    <SimpleSelectCheckbox record={record} handleChange={handleChange}/>

                                                ) : record.field_type === 'select_multiple' ? (
                                                    <MultiSelectCheckbox record={record} handleChange={handleChange}/>

                                                ) : record.field_type === 'map_editor' ? (
                                                    <MapEditor record={record} handleChangeMapValue={handleChangeMapValue}/>

                                                ) : (

                                                    <input
                                                        className="form-control date-value"
                                                        onChange={(e) => handleChange(e, record)}
                                                        value={record.value}
                                                        type={record.field_type}
                                                        name={record.id}
                                                        required={record.mandatory}
                                                    />

                                                )
                                            }
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>


                        <div className="col-md-4">
                            <Comments taskId={taskId} projectId={dataTask.project_id}/>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
}

export default Task