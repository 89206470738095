import React, { useState, useEffect } from "react";
import ModalClient from "./ModalClient.js";
import clientsService from "../../../services/clientsService.js";
import {useNavigate} from 'react-router-dom';
import LoadingContent from "../../public/LoadingContent.js";
import ModalLogo from "./ModalLogo.js";

const Clients = () => {

    const [clients, setClients] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalLogoOpen, setIsModalLogoOpen] = useState(false);
    const [editingClient, setEditingClient] = useState(null);
    const [imageClientId, setImageClientId] = useState(null);
    const navigateTo = useNavigate();
    const [loading, setLoading] = useState(true);
    const [logo, setLogo] = useState("");

    const loadContent = () => {
        setLoading(true);
        clientsService.getClients().then(data => {
            if(data.success){
                setClients(data.data);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        loadContent();
    }, []);

    const handleEditLogo = (client) => {
        setIsModalLogoOpen(true);
        setImageClientId(client.id);
        setLogo(client.logo)
    }

    const handleOpenModalForCreate = () => {
        setEditingClient(null);
        setIsModalOpen(true);
    };

    const handleEditClient = (client) => {
        setEditingClient(client);
        setIsModalOpen(true);
    };

    const handleDeleteClient = async (clientId) => {
        if (window.confirm("¿Estás seguro que deseas borrar esta municipalidad?")) {
            try {
                await clientsService.deleteClient(clientId);
                setClients(clients.filter((client) => client.id !== clientId));
            } catch (error) {
                console.error("Error deleting client:", error);
            }
        }
    };

    const handleNavigateToUsers = (clientId) => {
        navigateTo("/usuarios/" + clientId);
    }

    const handleCloseSession = async () => {
        if(window.confirm("¿Seguro que quieres cerrar tu sesión?")){
            localStorage.removeItem('access_token');
            navigateTo('/login');
        }
    }

    return (
        <div className="container-fluid">
            <div className="row vh-100">
                <div className="col-md-12 col-lg-12 p-4 content-container">

                    {loading && (
                        <LoadingContent/>
                    )}

                    {!loading && (
                        <div>
                            <h3 className="mb-4">Administración de Municipios</h3>
                            <button className="btn btn-primary mb-3" onClick={handleOpenModalForCreate}>
                                Crear Nuevo Municipio
                            </button>
                            &nbsp;
                            <button className="btn btn-secondary mb-3" onClick={handleCloseSession}>
                                Cerrar Sesión
                            </button>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Logo</th>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Teléfono</th>
                                    <th>Región</th>
                                    <th>Provincia</th>
                                    <th>Comuna</th>
                                    <th>Ciudad</th>
                                    <th>Dirección</th>
                                    <th>Contacto</th>
                                    <th>Alcalde</th>
                                    <th>Sitio web</th>
                                    <th>Tipo de municipio</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {Array.isArray(clients) && clients.map((client) => (
                                    <tr key={client.id}>
                                        <td>
                                            {client.logo && (
                                                <div className="image-preview" style={{ width: '100px' }}>
                                                    <img src={client.logo} alt="Vista previa" className="preview-img img-thumbnail" />
                                                </div>
                                            )}
                                        </td>
                                        <td>{client.name}</td>
                                        <td>{client.email}</td>
                                        <td>{client.phone}</td>
                                        <td>{client.state}</td>
                                        <td>{client.province}</td>
                                        <td>{client.commune}</td>
                                        <td>{client.city}</td>
                                        <td>{client.address}</td>
                                        <td>{client.contact_name}</td>
                                        <td>{client.mayor_name}</td>
                                        <td>{client.website}</td>
                                        <td>{client.client_type}</td>
                                        <td>
                                            <div className="btn-group" role="group">
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => handleEditLogo(client)}>
                                                    Logo
                                                </button>
                                                <button
                                                    className="btn btn-success btn-sm"
                                                    onClick={() => handleNavigateToUsers(client.id)}>
                                                    Usuarios
                                                </button>
                                                <button
                                                    className="btn btn-warning btn-sm"
                                                    onClick={() => handleEditClient(client)}>
                                                    Editar
                                                </button>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => handleDeleteClient(client.id)}>
                                                    Borrar
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {isModalOpen && (
                        <ModalClient
                            loadContent={loadContent}
                            isOpen={isModalOpen}
                            onClose={() => {
                                setIsModalOpen(false);
                            }}
                            editingModal={editingClient}
                        />
                    )}

                    {isModalLogoOpen && (
                        <ModalLogo
                            isOpen={isModalLogoOpen}
                            onClose={() => {
                                setIsModalLogoOpen(false);
                            }}
                            onUpload={(file) => {
                                setLoading(true);
                                clientsService.uploadLogo(file, imageClientId)
                                    .then(response => {
                                        setClients(prevClients =>
                                            prevClients.map(client =>
                                                client.id === imageClientId ? { ...client, logo: URL.createObjectURL(file)} : client
                                            )
                                        );
                                        setLoading(false);
                                    })
                                    .catch(error => ({ error, file }))
                            }}
                            logotipo={logo}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Clients;
