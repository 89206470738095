import React, { useState, useEffect } from 'react';
import SidebarNav from "../Sidebar/SidebarNav.js";
import usersService from "../../../services/usersService.js";
import {decodeJWT, isTokenExpired} from "../../../helpers/accessTokenHelper.js";

function Profile() {
    const [userData, setUserData] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        password: ''
    });

    useEffect(() => {
        const storedToken = localStorage.getItem('access_token');
        if (storedToken) {
            if (!isTokenExpired(storedToken)) {
                const decodedToken = decodeJWT(storedToken);
                const userId = decodedToken.data.id;
                usersService.getUser(userId).then((response) => {
                    if(response.success) {
                        console.log(response.data.mail);
                        setUserData(response.data);
                        setFormData({
                            name: response.data.name,
                            password: "***",
                            username: response.data.username,
                            email: response.data.mail,
                            lastLogin: response.data.last_login,
                            createdAt: response.data.created_at,
                            updatedAt: response.data.updated_at,
                            isActive: response.data.is_active,
                        });
                    }
                });
            }
        }
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    if (!userData) {
        return <div>Cargando...</div>;
    }

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                <SidebarNav/>

                <div className="p-4 content-container col-md-9 col-lg-10">
                    <h3 className="mb-4">
                        <i className="bi bi-person-fill"></i> Perfil de Usuario
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="profile-info">
                            {/* Campos editables */}
                            <div className="form-group">
                                <label htmlFor="name">Nombre</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <fieldset>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" checked={formData.isActive}
                                               onChange={handleChange}/>
                                        <label className="form-check-label">Activado</label>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Contraseña</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder="Ingresa una nueva contraseña"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="username">Nombre de Usuario</label>
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    value={formData.username}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Correo Electrónico</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastLogin">Último Login</label>
                                <input
                                    type="text"
                                    id="lastLogin"
                                    name="lastLogin"
                                    value={new Date(formData.lastLogin).toLocaleString()}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="createdAt">Fecha de Creación</label>
                                <input
                                    type="text"
                                    id="createdAt"
                                    name="createdAt"
                                    value={new Date(formData.createdAt).toLocaleString()}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="updatedAt">Fecha de Actualización</label>
                                <input
                                    type="text"
                                    id="updatedAt"
                                    name="updatedAt"
                                    value={new Date(formData.updatedAt).toLocaleString()}
                                    readOnly
                                />
                            </div>
                        </div>
                        <button className="btn btn-primary mb-3" type="submit">Guardar cambios</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Profile;
