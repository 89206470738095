import commonApiService from './commonApiService.js';

const login = async (username, password) => {
    const api = commonApiService.apiPublic();
    const response = await api.post('/login', {
        username,
        password,
    });
    try {
        return response.data;
    } catch (error) {
        return error.response?.data;
    }
};

const requestPasswordChange = async (userId, email) => {
    try {
        const response = await commonApiService.apiPublic().post(`/login/request-password-change-token`,{
            email: email,
            key: userId,
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al eliminar cliente');
    }
};

const changePassword = async (token, password) => {
    try {
        const response = await commonApiService.apiPublic().post(`/login/new-password`,{
            token: token,
            password: password,
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al eliminar cliente');
    }
};

const authService = {
    login,
    requestPasswordChange,
    changePassword,
};

export default authService;
