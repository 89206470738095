import React, {useEffect, useState} from "react";

const ModalLogo = ({ isOpen, onClose, onUpload, logotipo }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);

    const handleFileChange = (e) => {
        if (!e.target.files || e.target.files.length === 0) return;
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedFile) {
            onUpload(selectedFile);
            onClose();
        }
    };

    useEffect(() => {
        if (logotipo) {
            setPreview(logotipo);
        }
    }, [logotipo]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>&times;</button>
                <h2 className="modal-title">Subir Logo</h2>
                <form onSubmit={handleSubmit}>
                    {preview && (
                        <div className="image-preview">
                            <img src={preview} alt="Vista previa" className="preview-img" />
                        </div>
                    )}
                    <div className="form-group">
                        <label>Selecciona una imagen</label>
                        <input type="file" accept="image/*" onChange={handleFileChange} required />
                    </div>

                    <button type="submit" className="btn btn-primary">
                        <i className="bi bi-upload me-2"></i> Subir
                    </button>
                    <button type="button" className="btn btn-secondary ms-2" onClick={onClose}>
                        <i className="bi bi-x-circle me-2"></i> Cancelar
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ModalLogo;
