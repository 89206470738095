import React, {useEffect, useState} from "react";
import SidebarNav from "../Sidebar/SidebarNav.js";
import {Chart} from "react-google-charts";
import activitiesService from "../../../services/activitiesService.js";
import utilsHelper from "../../../helpers/utilsHelper.js";

const Gantt = () => {

    const data = [
        [
            { type: "string", label: "ID" },
            { type: "string", label: "Actividad" },
            { type: "string", label: "Tarea" },
            { type: "date", label: "Fecha inicio" },
            { type: "date", label: "Fecha término" },
            { type: "number", label: "Duración" },
            { type: "number", label: "Porcentaje completado" },
            { type: "string", label: "Depende de" },
        ],
    ];

    activitiesService.getActivities().then(dataResult => {
        dataResult.forEach((itemChart) => {
            const chartDataItem = [];
            chartDataItem.push(itemChart.id)
            chartDataItem.push(itemChart.name)
            chartDataItem.push(itemChart.name)
            chartDataItem.push(utilsHelper.convertIsoToJsDate(itemChart.estimated_start));
            chartDataItem.push(utilsHelper.convertIsoToJsDate(itemChart.estimated_end));
            chartDataItem.push(null)
            chartDataItem.push(50) //Porcentaje trabajado
            chartDataItem.push(null) //Dependiente de otro trabajo
            data.push(chartDataItem);
        });

    });

    const options = {
        height: 400,
        fontName: "News Cycle,Arial Narrow Bold,sans-serif",
        gantt: {
            criticalPathEnabled: true,
            arrow: {
                angle: 100,
                width: 2,
                color: "red",
                radius: 0,
            },
            labelStyle: {
                fontName: "News Cycle,Arial Narrow Bold,sans-serif",
                fontSize: 14,
                color: "#000",
            },
        },
        language: 'es'
    };

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                <SidebarNav/>

                <div className="p-4 content-container col-md-9 col-lg-10">
                    <h3 className="mb-4">
                        <i className="bi bi-bar-chart-steps"></i> Gantt
                    </h3>

                    <Chart
                        chartType="Gantt"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />

                </div>
            </div>
        </div>
    );
}

export default Gantt;