import React from 'react';
import LoadingContent from "./LoadingContent.js";
import ErrorContent from "./Error.js";

function Loading({validRoutes}) {

    if(validRoutes){
        var found = false;
        validRoutes.forEach(route => {
            if(window.location.pathname === route) {
                found = true;
            }
        });

        if(!found) {
            return(
                <ErrorContent errorMessage="La página que has ingresado no existe" />
            )
        }
    }

    return (
        <LoadingContent/>
    );
}

export default Loading;