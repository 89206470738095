import React, { useState, useEffect } from "react";
import customDataService from "../../../services/customDataService.js";

const ModalCustomFieldGroup = ({ isModalOpen, onClose, editingModal, loadingContent, titleSingular }) => {

    const [formData, setFormData] = useState({
        group_name: "",
        is_task_joined: false,
        is_project_joined: false,
    });

    useEffect(() => {
        if (editingModal) {
            setFormData({
                group_name: editingModal.group_name,
                is_task_joined: editingModal.is_task_joined,
                is_project_joined: editingModal.is_project_joined,
            });
        } else {
            setFormData({
                group_name: "",
                is_task_joined: false,
                is_project_joined: false,
            });
        }
    }, [editingModal]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const method = editingModal ? "PUT" : "POST";
        const payload = {
            ...formData,
        };

        if (method === "PUT") {
            await customDataService.updateCustomDataGroups(editingModal.id, payload);
            loadingContent()
            onClose()
        }

        if (method === "POST") {
            await customDataService.createCustomDataGroups(payload);
            loadingContent()
            onClose()
        }

    };

    if (!isModalOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <h2 className="modal-title">
                    {editingModal ? `Editar ${titleSingular}` : `Crear ${titleSingular}`}
                </h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Nombre del {titleSingular}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="group_name"
                            value={formData.group_name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <fieldset>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox"
                                       checked={formData.is_project_joined}
                                       name="is_project_joined"
                                       onChange={handleChange}/>
                                <label className="form-check-label">Mostrar en Proyectos</label>
                            </div>
                        </fieldset>
                    </div>

                    <div className="form-group">
                        <fieldset>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" checked={formData.is_task_joined}
                                       name="is_task_joined"
                                       onChange={handleChange}/>
                                <label className="form-check-label">Mostrar en Tareas</label>
                            </div>
                        </fieldset>
                    </div>

                    <button type="submit" className="btn btn-primary">
                        <i className="bi bi-save me-2"></i>
                        {editingModal ? "Guardar" : `Crear ${titleSingular}`}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary ms-2"
                        onClick={onClose}>
                        <i className="bi bi-x-circle me-2"></i>
                        Cancelar
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ModalCustomFieldGroup;
