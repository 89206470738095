import React, {useEffect, useState} from "react";
import SidebarNav from "../Sidebar/SidebarNav.js";
import {useLocation, useNavigate} from "react-router-dom";
import customDataService from "../../../services/customDataService.js";
import utilsHelper from "../../../helpers/utilsHelper.js";
import ModalCustomField from "./ModalCustomField.js";
import LoadingContent from "../../public/LoadingContent.js";

const ListCustomFields = () => {

    const titleSingular = 'dato personalizado';
    const titlePlural = 'Datos personalizados';

    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [editingModal, setEditingModal] = useState(null);
    const [loading, setLoading] = useState(true);

    const loadingContent = () => {
        setLoading(true);
        customDataService.getCustomDataFields(location.state.id).then(data => {
            data.forEach((item) => {
                if(typeof item.option_list !== "undefined" ){
                    try {
                        item.option_list = JSON.parse(item.option_list);
                    } catch (e) {}
                }
            });
            setData(data);
            setLoading(false);
        });
    };

    const handleCreate = () => {
        setEditingModal(null);
        setModalOpen(true);
    };

    const handleEdit = (data) => {
        setEditingModal(data);
        setModalOpen(true);
    };

    const handleDelete = async (itemId) => {
        if(window.confirm("¿Seguro que deseas eliminar este campo personalizado?")) {
            await customDataService.deleteCustomDataFields(itemId);
            loadingContent()
        }
    };

    useEffect(() => {
        loadingContent();
    },[]);

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                {isModalOpen && (
                    <ModalCustomField
                        groupId={location.state.id}
                        isModalOpen={isModalOpen}
                        onClose={() => setModalOpen(false)}
                        editingModal={editingModal}
                        loadingContent={loadingContent}
                        titleSingular={titleSingular}
                    />
                )}

                <SidebarNav/>

                <div className="p-4 content-container col-md-9 col-lg-10">

                    {loading && (
                        <LoadingContent/>
                    )}

                    {!loading && (
                        <div>
                            <h3 className="mb-4">
                                <i className="bi bi-ui-checks-grid"></i> Administración de {titlePlural}
                            </h3>
                            <h5 className="mb-4">
                                {location.state.group_name}
                            </h5>
                            <button className="btn btn-secondary mb-3" style={{marginRight: '4px'}}
                                    onClick={() => navigate(-1)}>
                                <i className="bi bi-arrow-left"></i> Atrás
                            </button>
                            <button className="btn btn-primary mb-3" onClick={handleCreate}>
                                Crear Nuevo Campo
                            </button>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Tipo</th>
                                    <th>Obligatorio</th>
                                    <th>Fecha de creación</th>
                                    <th>Fecha de actualización</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.field_name}</td>
                                        <td>{item.field_type}</td>
                                        <td>{item.mandatory ?'Si':'No'}</td>
                                        <td>{utilsHelper.formatDate(item.created_at, true)}</td>
                                        <td>{utilsHelper.formatDate(item.updated_at, true)}</td>
                                        <td>
                                            <button
                                                className="btn btn-warning btn-sm me-2"
                                                onClick={() => handleEdit(item)}
                                            >
                                                Editar
                                            </button>
                                            <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() => handleDelete(item.id)}
                                            >
                                                Borrar
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
}

export default ListCustomFields;