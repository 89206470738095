import commonApiService from './commonApiService.js';
import {getTokenHeader} from "../helpers/accessTokenHelper.js";

const createClient = async (clientData) => {
    try {
        const response = await commonApiService.apiPrivate().post('/clients', clientData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al registrar cliente');
    }
};

const getClients = async () => {
    try {
        const token = localStorage.getItem('access_token');
        const response = await commonApiService.apiPrivate().get('/clients', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al obtener clientes');
    }
};

const getClient = async (clientId) => {
    try {
        const token = localStorage.getItem('access_token');
        const response = await commonApiService.apiPrivate().get(`/clients/${clientId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al obtener clientes');
    }
};

const updateClient = async (clientId, clientData) => {
    try {
        const response = await commonApiService.apiPrivate().put(`/clients/${clientId}`, clientData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al actualizar cliente');
    }
};

const deleteClient = async (clientId) => {
    try {
        const response = await commonApiService.apiPrivate().delete(`/clients/${clientId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al eliminar cliente');
    }
};

const uploadLogo = async (file, clientId) => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("client_id", clientId);

        const response = await commonApiService.apiPrivate().post('/clients/upload-logo', formData, {
            headers: {
                ...getTokenHeader().headers,
                'Content-Type': 'multipart/form-data',
            }
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al subir el archivo');
    }
};

const clientsService = {
    createClient,
    getClients,
    getClient,
    updateClient,
    deleteClient,
    uploadLogo,
};

export default clientsService;
