import commonApiService from "./commonApiService.js";
import {getTokenHeader} from "../helpers/accessTokenHelper.js";

const getCustomDataGroups = async () => {
    try {
        const response = await commonApiService.apiPrivate().get('/custom-data-group', getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al obtener los datos');
    }
};

const getCustomDataGroupsForTasks = async () => {
    try {
        const response = await commonApiService.apiPrivate().get('/custom-data-group/for/tasks', getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al obtener los datos');
    }
};

const createCustomDataGroups = async (payload) => {
    try {
        const response = await commonApiService.apiPrivate().post(`/custom-data-group`, payload, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al crear los grupos de datos');
    }
};

const updateCustomDataGroups = async (groupId, payload) => {
    try {
        const response = await commonApiService.apiPrivate().put(`/custom-data-group/${groupId}`, payload, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al actualizar los datos');
    }
};

const deleteCustomDataGroups = async (itemId) => {
    try {
        const response = await commonApiService.apiPrivate().delete(`/custom-data-group/${itemId}`, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al eliminar los datos');
    }
};

const getCustomDataFields = async (groupId, taskId) => {
    try {
        const response = await commonApiService.apiPrivate().get(`/custom-data/${groupId}/${taskId}`, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al obtener los datos');
    }
};

const createCustomDataFields = async (payload) => {
    try {
        const response = await commonApiService.apiPrivate().post(`/custom-data`, payload, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al crear los datos');
    }
};

const updateCustomDataFields = async (payload) => {
    try {
        const response = await commonApiService.apiPrivate().put(`/custom-data`, payload, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al actualizar los datos');
    }
};

const deleteCustomDataFields = async (itemId) => {
    try {
        const response = await commonApiService.apiPrivate().delete(`/custom-data/${itemId}`, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al eliminar los datos');
    }
};

const customDataService = {
    getCustomDataGroups,
    getCustomDataGroupsForTasks,
    createCustomDataGroups,
    updateCustomDataGroups,
    deleteCustomDataGroups,
    getCustomDataFields,
    createCustomDataFields,
    updateCustomDataFields,
    deleteCustomDataFields
}

export default customDataService;