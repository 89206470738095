import commonApiService from "./commonApiService.js";
import {getTokenHeader} from "../helpers/accessTokenHelper.js";

const saveCustomDataValues = async (payload) => {
    try {
        const response = await commonApiService.apiPrivate().post(`/custom-data-values`, payload, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al guardar los datos');
    }
};

const customDataValuesService = {
    saveCustomDataValues,
}

export default customDataValuesService;