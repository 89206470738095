import React, { useState, useEffect } from "react";

const MultiSelectCheckbox = ({ record, recordIndex, handleChange }) => {
    const [selection, setSelection] = useState([]);

    useEffect(() => {
        const newSelection = record.option_list.map(option => ({
            value: option, checked: false
        }));
        try {
            const values = JSON.parse(record.value);
            const updatedSelection = newSelection.map(sel => ({
                ...sel,
                checked: values.some(item => item.value === sel.value && item.checked)
            }));
            setSelection(updatedSelection);
        } catch (error) {
            setSelection(newSelection);
        }
    }, [record]);

    const handleChangeValue = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;

        setSelection(prevSelection => {
            const existingIndex = prevSelection.findIndex(item => item.value === value);

            if (existingIndex !== -1) {
                const updatedSelection = [...prevSelection];
                updatedSelection[existingIndex].checked = checked;
                return updatedSelection;
            } else {
                return [...prevSelection, { value, checked }];
            }
        });

        record.value = selection;
        handleChange(e, record, recordIndex);
    };

    return (
        <fieldset>
            {selection.map((option, index) => (
                <div key={index} className="form-check form-switch">
                    <input
                        name="mandatory"
                        onChange={handleChangeValue}
                        value={option.value}
                        checked={option.checked}
                        className="form-check-input"
                        type="checkbox"
                    />
                    <label className="form-check-label">{option.value}</label>
                </div>
            ))}
        </fieldset>
    );
};

export default MultiSelectCheckbox;
