import React, { useState, useEffect } from "react";
import activitiesService from "../../../services/activitiesService.js";
import utilsHelper from "../../../helpers/utilsHelper.js";

const ModalTask = ({ isOpen, onClose, editingModal, loadContent, titleSingular,  dataProjects, parentTask, projectId }) => {

    const defaultInitialFormData = {
        name: "",
        description: "",
        estimated_start: "",
        estimated_end: "",
        project_id: "",
    }
    if(dataProjects.length === 1) {
        defaultInitialFormData.project_id = dataProjects[0].id;
    }
    const [formData, setFormData] = useState(defaultInitialFormData);

    useEffect(() => {
        if (editingModal) {
            setFormData(editingModal);
        } else {
            setFormData(defaultInitialFormData);
        }
        if(projectId != ''){
            setFormData({
                ...formData,
                ['project_id']: projectId,
            });
        }
    }, [editingModal, dataProjects, projectId]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (parentTask && parentTask.id) {
            formData.parent_task_id = parentTask.id;
        }

        const method = editingModal ? "PUT" : "POST";
        if (method === "POST") {
            activitiesService.createActivity(formData).then(data => {
                if(data.success) {
                    onClose();
                    loadContent();
                }
            });
        }

        if (method === "PUT") {
            activitiesService.updateActivity(editingModal.id, {
                name: formData.name,
                description: formData.description,
                estimated_start: formData.estimated_start,
                estimated_end: formData.estimated_end,
                status_id: formData.status_id,
            }).then(data => {
                if(data.success) {
                    onClose();
                    loadContent();
                }
            });
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <h2 className="modal-title">
                    {editingModal ? `Editar ${titleSingular}` : `Crear ${titleSingular}`}
                </h2>
                {parentTask && (
                    <p className="modal-title">
                        {parentTask.name}
                    </p>
                )}
                <form onSubmit={handleSubmit}>

                    {projectId != ''  && (
                        <input type="hidden" value={formData.project_id}  id="project_id" name="project_id"/>
                    )}

                    {projectId == '' && (
                        <div className="form-group">
                            <label htmlFor="project_id">Programa</label>
                            <select
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        project_id: e.target.value,
                                    });
                                }}
                                required
                                id="project_id"
                                name="project_id"
                                value={formData.project_id}
                                className="form-select">
                                <option value="">--- Seleccionar ---</option>
                                {dataProjects.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.id}
                                        selected
                                    >
                                        {option.project_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}


                    <div className="form-group">
                        <label>Nombre de la {titleSingular}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Descripción</label>
                        <textarea
                            className="form-control"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            rows="3"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Fecha estimada de Inicio</label>
                        <input
                            type="date"
                            className="form-control"
                            name="estimated_start"
                            value={utilsHelper.onlyDate(formData.estimated_start)}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="form-group">
                        <label>Fecha estimada de Término</label>
                        <input
                            type="date"
                            className="form-control"
                            name="estimated_end"
                            value={utilsHelper.onlyDate(formData.estimated_end)}
                            onChange={handleChange}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary">
                        <i className="bi bi-save me-2"></i>
                        {editingModal ? "Guardar" : `Crear ${titleSingular}`}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary ms-2"
                        onClick={onClose}>
                        <i className="bi bi-x-circle me-2"></i>
                        Cancelar
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ModalTask;
