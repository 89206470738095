import React, { useState, useEffect } from "react";

const SimpleSelectCheckbox = ({ record, handleChange }) => {
    const [selection, setSelection] = useState("");

    useEffect(() => {
        setSelection(record.value || "");
    }, [record]);

    const handleChangeValue = (e) => {
        const newValue = e.target.value;
        setSelection(newValue);

        if (handleChange) {
            record.value = newValue;
            console.log(record.value, newValue);
            handleChange(e, record);
        }
    };

    return (
        <select className="form-control" onChange={handleChangeValue} value={selection}>
            <option value="">--- Seleccionar ---</option>
            {Array.isArray(record.option_list) && record.option_list.map((item, index) => (
                <option key={index} value={item}>{item}</option>
            ))}
        </select>
    );
};

export default SimpleSelectCheckbox;
