import { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Polyline, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const customMarkerIcon = new L.Icon({
    iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const MapEditor = ({ record, handleChangeMapValue }) => {
    const [mode, setMode] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [lines, setLines] = useState([]);
    const [allPoints, setAllPoints] = useState([]);
    const mapRef = useRef(null);

    const setMapCursor = (cursorType) => {
        if (mapRef.current) {
            const mapContainer = mapRef.current.getContainer();
            mapContainer.style.cursor = cursorType;
        }
    };

    const handleModeChange = (newMode, cursorType) => {
        setMode(newMode);
        setMapCursor(cursorType);
    };

    const handleChange = (newPoint) => {
        setAllPoints((prev) => {
            if (!prev.some(p => p.lat === newPoint.lat && p.lng === newPoint.lng)) {
                const updatedPoints = [...prev, newPoint];
                if (updatedPoints.length > 0) {
                    record.value = JSON.stringify(updatedPoints);
                }
                handleChangeMapValue(record);
                return updatedPoints;
            }
            return prev;
        });
    };

    const clearMap = () => {
        setMarkers([]);
        setLines([]);
        setAllPoints([]);
        setMode(null);
    };

    const MapEvents = () => {
        const map = useMap();
        mapRef.current = map;

        useEffect(() => {
            const handleClick = (e) => {
                if (mode === "marker") {
                    setMarkers((prev) => [...prev, e.latlng]);
                    handleChange(e.latlng);
                } else if (mode === "polyline") {
                    setLines((prev) => [...prev, e.latlng]);
                    handleChange(e.latlng);
                }
            };

            map.on("click", handleClick);

            return () => {
                map.off("click", handleClick);
            };
        }, [mode]); // Se ejecuta solo cuando cambia `mode`

        return null;
    };

    return (
        <div style={{ position: "relative" }}>
            <MapContainer center={[-33.4489, -70.6693]} zoom={11} style={{ height: "400px", width: "100%" }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MapEvents />

                {markers.map((position, index) => (
                    <Marker key={index} position={position} icon={customMarkerIcon} />
                ))}

                {lines.length > 1 && <Polyline positions={lines} color="blue" />}
            </MapContainer>

            <div
                style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    display: "flex",
                    gap: "3px",
                    zIndex: 1000
                }}
            >
                <button
                    style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "green",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                        cursor: "pointer"
                    }}
                    onClick={() => handleModeChange("marker", "crosshair")}
                >
                    <i className="bi bi-pin-fill" style={{ color: "white", fontSize: "16px" }}></i>
                </button>

                <button
                    style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "green",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                        cursor: "pointer"
                    }}
                    onClick={() => handleModeChange("polyline", "crosshair")}
                >
                    <i className="bi bi-bounding-box-circles" style={{ color: "white", fontSize: "16px" }}></i>
                </button>

                <button
                    style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "red",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                        cursor: "pointer"
                    }}
                    onClick={clearMap}
                >
                    <i className="bi bi-trash" style={{ color: "white", fontSize: "16px" }}></i>
                </button>
            </div>
        </div>
    );
};

export default MapEditor;
