import React, { useEffect, useState } from "react";
import commentsService from "../../../services/commentsService.js";
import utilsHelper from "../../../helpers/utilsHelper.js";

const Comments = ({ taskId, projectId }) => {
    const resetData = {
        task_id: taskId,
        content: "",
        content_type: "text",
        attachments: []
    };
    const [formData, setFormData] = useState(resetData);
    const [commentsData, setCommentsData] = useState([]);

    const loadContent = (taskId) => {
        commentsService.getComments(taskId).then((data) => {
            if (data && data.length > 0) {
                setCommentsData(data);
            }
        });
    };

    const resetFormData = () => {
        setFormData(resetData);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.content !== "" || formData.attachments.length > 0) {

            let formDataNA = { ...formData };
            formDataNA.attachments = [];

            commentsService.createComment(formDataNA).then((response) => {
                if (response.success) {
                    const uploadPromises = formData.attachments.map((file) =>
                        commentsService.uploadFile(projectId, taskId, response.comment_id, file)
                            .catch(error => ({ error, file }))
                    );

                    Promise.allSettled(uploadPromises).then((results) => {
                        const failedUploads = results.filter(result => result.status === "rejected");

                        if (failedUploads.length > 0) {
                            console.warn("Algunos archivos no se subieron:", failedUploads);
                        }

                        loadContent(taskId);
                        resetFormData();
                    });
                } else {
                    resetFormData();
                }
            });

        }
    };

    const handleChangeContent = (event) => {
        setFormData({ ...formData, content: event.target.value });
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setFormData({ ...formData, attachments: [...formData.attachments, ...files] });
    };

    const handleRemoveFile = (index) => {
        setFormData({
            ...formData,
            attachments: formData.attachments.filter((_, i) => i !== index),
        });
    };

    const isImageFile = (file) => {
        return file && file.type.startsWith('image/');
    };

    useEffect(() => {
        loadContent(taskId);
    }, [taskId]);

    return (
        <div className="comments-container">
            <div className="comment-box-container">
                <form onSubmit={handleSubmit}>
                    <textarea className="comment-box" value={formData.content} onChange={handleChangeContent} placeholder="Observaciones..."></textarea>

                    <div className="attachments-list">
                        {formData.attachments.map((file, index) => (
                            <div key={index} className="attachment-item">
                                <span>{file.name}</span>
                                {isImageFile(file) ? (
                                    <div className="image-preview">
                                        <img src={URL.createObjectURL(file)} alt={file.name} style={{ width: '100px', height: 'auto' }} />
                                    </div>
                                ) : (
                                    <a href={URL.createObjectURL(file)} download={file.name}>Descargar</a>
                                )}
                                <button className="remove-attachment btn btn-danger btn-sm" onClick={() => handleRemoveFile(index)}>
                                    <i className="bi bi-x-lg"></i>
                                </button>
                            </div>
                        ))}
                    </div>

                    <div className="comment-actions">
                        <input type="file" id="file-upload" multiple onChange={handleFileChange} hidden />
                        <label htmlFor="file-upload" className="action-btn" title="Adjuntar Archivo">
                            <i className="bi bi-paperclip"></i>
                        </label>
                        <button type="submit" className="send-btn" title="Enviar">
                            <i className="bi bi-send"></i> Enviar
                        </button>
                    </div>
                </form>
            </div>

            <div className="comment-list-container">
                {Array.isArray(commentsData) && commentsData.map((comment) => (
                    <div className="comment" key={comment.id}>
                        <div className="user-info">
                            <div className="user-avatar">
                                <span className="initials">{utilsHelper.shortenName(comment.user.name)}</span>
                            </div>
                            <div className="user-details">
                                <h5 className="user-name">{comment.user.name}</h5>
                                <p className="comment-date">Hace 2 horas</p>
                            </div>
                        </div>
                        <p className="user-comment">{comment.content}</p>
                        <div className="attachments">
                            {comment.attachments && comment.attachments.map((attachment, index) => {
                                const fileName = attachment.split('/').pop();
                                const fileExtension = fileName.split('.').pop().toLowerCase();

                                return (
                                    <div key={index} className="attachment-item">
                                        {['jpg', 'jpeg', 'png', 'gif', 'bmp', 'avif'].includes(fileExtension) ? (
                                            <div className="image-preview">
                                                <a target="_blank" href={attachment} download={fileName}>
                                                    <img src={attachment} alt={fileName} style={{ width: '100px', height: 'auto' }} />
                                                </a>
                                            </div>
                                        ) : (
                                            <a target="_blank" href={attachment} download={fileName}>
                                                {fileName}
                                            </a>
                                        )}
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Comments;
