import React, {useEffect, useState} from "react";
import Autosuggest from "react-autosuggest";
import usersService from "../../../services/usersService.js";
import UtilsHelper from "../../../helpers/utilsHelper.js";


const UserAssign = ({setShowUsersAssign, addUserToActivity}) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const usersLocal = [];
        usersService.getUsers().then(data => {
            if(data.success) {
                data.data.forEach(user => {
                    usersLocal.push({
                        id: user.id,
                        name: user.name,
                    });
                });
                setUsers(usersLocal);
            }
        });
    }, []);

    const onSearchChange = (_, { newValue }) => {
        setSearchQuery(newValue);
    };

    const filteredUsers = users.filter((user) =>
        `${user.name}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0
            ? []
            : filteredUsers.filter(
                (user) =>
                    user.name.toLowerCase().includes(inputValue)
            );
    };

    const renderSuggestion = (user) => (
        <div key={user.id} className="user-list-item" onClick={() => onSelectItem(user)}>
            <div className="user-item-content">
                <span className="user-initials">
                    {UtilsHelper.shortenName(user.name)}
                </span>
                <span className="user-name">{user.name}</span>
            </div>
        </div>
    );

    const onSelectItem = (item) =>{
        setShowUsersAssign(false);
        addUserToActivity(item);
    }

    return (
        <div className="card-float">
            <div className="button-assign-users-container">
                <button className="round-button round-close-button" onClick={() => setShowUsersAssign(false)}>
                    <i className="bi bi-x"></i>
                </button>
            </div>
            <div className="user-list-container">
                <h5>Asignar usuario:</h5>
                <div className="search-container">
                    <Autosuggest
                        suggestions={getSuggestions(searchQuery)}
                        onSuggestionsFetchRequested={({value}) => setSearchQuery(value)}
                        onSuggestionsClearRequested={() => setSearchQuery("")}
                        getSuggestionValue={(suggestion) => `${suggestion.name}`}
                        renderSuggestion={renderSuggestion}
                        inputProps={{
                            placeholder: "Buscar usuario ...",
                            value: searchQuery,
                            onChange: onSearchChange,
                            className: "search-input",
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserAssign;
