import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from '../pages/public/Login.js';
import PrivateRoute from "./PrivateRoute.js";
import {AuthProvider} from "../AuthContext.js";
import Profile from "../pages/private/Profile/Profile.js";
import Users from "../pages/private/Users/Users.js";
import Status from "../pages/private/Status/Status.js";
import Projects from "../pages/private/Projects/Projects.js";
import Tasks from "../pages/private/Tasks/Tasks.js";
import Loading from "../pages/public/Loading.js";
import Task from "../pages/private/Tasks/Task.js";
import Clients from "../pages/private/Clients/Clients.js";
import Recovery from "../pages/public/Recovery.js";
import ListCustomFieldsGroups from "../pages/private/CustomFields/ListCustomFieldsGroups.js";
import ListCustomFields from "../pages/private/CustomFields/ListCustomFields.js";
import Gantt from "../pages/private/Gantt/Gantt.js";
import Dashboard from "../pages/private/Dashboard/Dashboard.js";

const AppRoutes = () => {

    const routeDefinitions = [
        { path: "/login", element: <Login /> },
        { path: "/recuperacion/:token", element: <Recovery /> },
        { path: "/dashboard", element: <Dashboard /> },
        //{ path: "/roles", element: <PrivateRoute><Roles /></PrivateRoute> },
        { path: "/municipios", element: <PrivateRoute><Clients /></PrivateRoute> },
        { path: "/usuarios", element: <PrivateRoute><Users /></PrivateRoute> },
        { path: "/usuarios/:clientId", element: <PrivateRoute><Users /></PrivateRoute> },
        { path: "/datos-personalizados", element: <PrivateRoute><ListCustomFieldsGroups /></PrivateRoute> },
        { path: "/datos-personalizados/:groupId", element: <PrivateRoute><ListCustomFields /></PrivateRoute> },
        { path: "/estados", element: <PrivateRoute><Status /></PrivateRoute> },
        { path: "/programas", element: <PrivateRoute><Projects /></PrivateRoute> },
        { path: "/gantt", element: <PrivateRoute><Gantt /></PrivateRoute> },
        { path: "/perfil", element: <PrivateRoute><Profile /></PrivateRoute> },
        { path: "/actividades", element: <PrivateRoute><Tasks /></PrivateRoute> },
        { path: "/actividades/:projectId", element: <PrivateRoute><Tasks /></PrivateRoute> },
        { path: "/actividad/:taskId", element: <PrivateRoute><Task /></PrivateRoute> },
    ];

    const validRoutes = routeDefinitions.map(route => route.path);

    return (
        <Router>
            <AuthProvider>
                <Routes>
                    {routeDefinitions.map(({ path, element }) => (
                        <Route key={path} path={path} element={element} />
                    ))}
                    <Route
                        path="*"
                        element={
                            <Loading
                                validRoutes={validRoutes}
                            />
                        }
                    />
                </Routes>
            </AuthProvider>
        </Router>
    );

};

export default AppRoutes;
