import { format } from "date-fns";

const formatDate = (dateString, includeTime = false) => {
    if(dateString == null){
        return "Indefinido";
    }
    const date = new Date(dateString);
    if (includeTime) {
        return format(date, "dd/MM/yyyy HH:mm");
    }
    return format(date, "dd/MM/yyyy");
};

const onlyDate = (dateString) => {
    if(dateString == null){
        return null;
    }
    if(dateString === ""){
        return null;
    }
    const date = new Date(dateString);
    return format(date, "yyyy-MM-dd");
};

const shortenName = (fullName) => {
    if (fullName && typeof fullName === 'string') {
        const nameParts = fullName.trim().split(' ');

        const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
        const lastNameInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();

        return firstNameInitial + lastNameInitial;
    }
    return '';
};

function convertIsoToJsDate(isoString) {
    const date = new Date(isoString);

    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();

    return new Date(year, month, day);
}

export default {
    formatDate,
    shortenName,
    convertIsoToJsDate,
    onlyDate
}
