import React, { useEffect, useState } from "react";
import SidebarNav from "../Sidebar/SidebarNav.js";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Card } from "react-bootstrap";
import chartsService from "../../../services/chartsService.js";
import LoadingContent from "../../public/LoadingContent.js";

const Dashboard = () => {

    const [tasksByStatus, setTasksByStatus] = useState([]);
    const [tasksByUsers, setTasksByUsers] = useState([]);
    const [tasksByCustomData, setTasksByCustomData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(true);

    let loadedCount = 0;

    useEffect(() => {
        setLoading(true);

        const today = new Date();
        const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastDayThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const start_date = firstDayLastMonth.toISOString().split("T")[0];
        const end_date = lastDayThisMonth.toISOString().split("T")[0];
        setStartDate(start_date);
        setEndDate(end_date);

        GetTasksCountGroupedByStatus(start_date, end_date);
        GetTasksCountGroupedByUsers(start_date, end_date);
        GetTasksCountByCustomData(start_date, end_date);
    }, []);

    const GetTasksCountGroupedByStatus = (start_date, end_date) => {
        chartsService.getTasksCountGroupedByStatus(start_date, end_date).then(data => {
            const tasks = [];
            if (data && data.length > 0) {
                data.forEach(item => {
                    tasks.push({
                        name: item.Status,
                        Tareas: item.Tasks,
                        color: item.Color
                    });
                });
            }
            setTasksByStatus(tasks);
            loadedCount++;
            if (loadedCount === 3){
                setLoading(false);
            }
        });
    }

    const GetTasksCountGroupedByUsers = (start_date, end_date) => {
        chartsService.getTasksCountGroupedByUsers(start_date, end_date).then(data => {
            const tasks = [];
            if (data && data.length > 0) {
                data.forEach(item => {
                    tasks.push({
                        name: item.User,
                        Tareas: item.Tasks,
                    });
                });
            }
            setTasksByUsers(tasks);
            loadedCount++;
            if (loadedCount === 3){
                setLoading(false);
            }
        });
    }

    const GetTasksCountByCustomData = (start_date, end_date) => {
        chartsService.getTasksCountByCustomData(start_date, end_date).then(data => {
            setTasksByCustomData(data);
            loadedCount++;
            if (loadedCount === 3){
                setLoading(false);
            }
        });
    }

    const handleFilter = () => {
        setLoading(true);
        GetTasksCountGroupedByStatus(startDate, endDate);
        GetTasksCountGroupedByUsers(startDate, endDate);
        GetTasksCountByCustomData(startDate, endDate);
    }

    return (
        <div className="container-fluid">
            <div className="row vh-100">
                <SidebarNav />



                <div className="col-md-9 col-lg-10 p-4 content-container">

                    {loading && (
                        <LoadingContent/>
                    )}

                    {!loading && (
                        <>
                            <h3 className="mb-4">Dashboard</h3>

                            <div className="row">
                                <div className="col col-3">
                                    <label htmlFor="start_date" className="form-label mt-4">Fecha Inicio</label>
                                    <input className="form-control" name="start_date" id="start_date" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                </div>
                                <div className="col col-3">
                                    <label htmlFor="end_date" className="form-label mt-4">Fecha Fin</label>
                                    <input className="form-control" name="end_date" id="end_date" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                </div>
                                <div className="col col-3">
                                    <button className="btn btn-success" style={{marginTop: '54px'}} onClick={() => handleFilter()}>Filtrar</button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col col-4">
                                    <Card className="shadow-sm" style={{marginTop: '30px'}}>
                                        <Card.Header className="bg-secondary text-white">
                                            <h5 className="mb-0">Tareas por estado</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            {tasksByStatus.length > 0 ? (
                                                <ResponsiveContainer width="100%" height={300}>
                                                    <BarChart data={tasksByStatus}>
                                                        <XAxis dataKey="name" />
                                                        <YAxis />
                                                        <Tooltip />
                                                        <Bar dataKey="Tareas">
                                                            {tasksByStatus.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                            ))}
                                                        </Bar>
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            ) : (
                                                <p>No hay datos disponibles</p>
                                            )}
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className="col col-4">
                                    <Card className="shadow-sm" style={{marginTop: '30px'}}>
                                        <Card.Header className="bg-secondary text-white">
                                            <h5 className="mb-0">Tareas por usuarios asignados</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            {tasksByUsers.length > 0 ? (
                                                <ResponsiveContainer width="100%" height={300}>
                                                    <BarChart data={tasksByUsers}>
                                                        <XAxis dataKey="name" />
                                                        <YAxis />
                                                        <Tooltip />
                                                        <Bar dataKey="Tareas">
                                                            {tasksByUsers.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill="#4e97D1" />
                                                            ))}
                                                        </Bar>
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            ) : (
                                                <p>No hay datos disponibles</p>
                                            )}
                                        </Card.Body>
                                    </Card>
                                </div>

                                {tasksByCustomData?.length > 0 ? (
                                    tasksByCustomData.map((entry, index) => (
                                        <div key={index} className="col col-4">
                                            <Card className="shadow-sm" style={{ marginTop: '30px' }}>
                                                <Card.Header className="bg-secondary text-white">
                                                    <h5 className="mb-0">{entry.category}</h5>
                                                </Card.Header>
                                                <Card.Body>
                                                    {entry.results.length > 0 ? (
                                                        <ResponsiveContainer width="100%" height={300}>
                                                            <BarChart data={entry.results}>
                                                                <XAxis dataKey="title" />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Bar dataKey="tasks">
                                                                    {entry.results.map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill="#008f39" />
                                                                    ))}
                                                                </Bar>
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    ) : (
                                                        <p>No hay datos disponibles</p>
                                                    )}
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))
                                ) : (
                                    <div></div>
                                )}


                            </div>
                        </>
                    )}

                </div>
            </div>
        </div>
    );
}

export default Dashboard;
