import React from 'react';

function LoadingContent() {
    return (
        <div className="login-container d-flex align-items-center justify-content-center">
            <div className="loading-container">
                <img src="/logo-rcj.png" style={{width: '150px', marginBottom: '10px'}} className="logo-login-top" alt="Red Ciudad Joven"/>
                <div className="spinner"></div>
            </div>
        </div>
    )
}

export default LoadingContent;