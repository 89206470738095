import commonApiService from "./commonApiService.js";
import {getTokenHeader} from "../helpers/accessTokenHelper.js";

const getTasksCountGroupedByStatus = async (startDate, endDate) => {
    try {
        const response = await commonApiService.apiPrivate().get(`/charts/grouped-by-status?start=${startDate}&end=${endDate}`, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al obtener tasks grouped by status');
    }
};

const getTasksCountGroupedByUsers = async (startDate, endDate) => {
    try {
        const response = await commonApiService.apiPrivate().get(`/charts/grouped-by-users?start=${startDate}&end=${endDate}`, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al obtener tasks grouped by users');
    }
};

const getTasksCountByCustomData = async (startDate, endDate) => {
    try {
        const response = await commonApiService.apiPrivate().get(`/charts/grouped-by-custom-data?start=${startDate}&end=${endDate}`, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al obtener tasks grouped by custom data');
    }
};

const chartsService = {
    getTasksCountGroupedByStatus,
    getTasksCountGroupedByUsers,
    getTasksCountByCustomData
}

export default chartsService;