import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import {decodeJWT, getCurrentToken, isTokenExpired} from "../../../helpers/accessTokenHelper.js";
import clientsService from "../../../services/clientsService.js";

const SidebarNav = () => {
    const [showParametros, setShowParametros] = useState(false);
    const [logo, setLogo] = useState(null);

    const toggleParametros = () => {
        setShowParametros(!showParametros);
    };

    const logout = () => {
        if (window.confirm('¿Estás seguro que deseas cerrar la sesión?')) {
            localStorage.clear();
            window.location.reload();
        }
    };

    useEffect(() => {
        const storeToken = getCurrentToken();
        if(!isTokenExpired(storeToken)){
            const decodedToken = decodeJWT(storeToken);
            if (decodedToken.data.client_id) {
                clientsService.getClient(decodedToken.data.client_id).then(response => {
                   if(response.data.logo){
                       setLogo(response.data.logo);
                   }
                });
            }
        }
    });

    return (
        <div className="col-md-3 col-lg-2 bg-dark text-white p-4 sidebar">
            <div className="logo-sidebar">
                <Link to="/dashboard">
                    <img src="/logo-rcj-white.png" className="logo-login-top" alt="Red Ciudad Joven" />
                </Link>
            </div>
            {logo && (
                <div className="logo-sidebar">
                    <Link to="/dashboard">
                        <img src={logo} className="logo-login-top" alt="Red Ciudad Joven" />
                    </Link>
                </div>
            )}
            <ul className="nav flex-column">
                <li className="nav-item">
                    <Link className="nav-link text-white" to="/perfil">
                        <i className="bi bi-person-fill"></i> Mi Perfil
                    </Link>
                </li>
                <li className="nav-item dropdown position-relative" onMouseEnter={toggleParametros} onMouseLeave={toggleParametros}>
                    <a className="nav-link text-white" href="#" role="button">
                        <i className="bi bi-gear"></i> Parámetros <i className="bi bi-chevron-right"></i>
                    </a>
                    <ul className={`dropdown-menu bg-dark position-absolute start-100 top-0 ${showParametros ? 'show' : ''}`}>
                        <li>
                            <Link className="dropdown-item text-white" to="/usuarios">
                                <i className="bi bi-people-fill"></i> Usuarios
                            </Link>
                        </li>
                        <li>
                            <Link className="dropdown-item text-white" to="/datos-personalizados">
                                <i className="bi bi-ui-checks-grid"></i> Datos Personalizados
                            </Link>
                        </li>
                        <li>
                            <Link className="dropdown-item text-white" to="/estados">
                                <i className="bi bi-card-checklist"></i> Estados
                            </Link>
                        </li>
                    </ul>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-white" to="/programas">
                        <i className="bi bi-briefcase"></i> Programas
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-white" to="/gantt">
                        <i className="bi bi-bar-chart-steps"></i> Gantt
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-white" to="/actividades">
                        <i className="bi bi-list-task"></i> Actividades
                    </Link>
                </li>
            </ul>
            <button className="logout-btn btn w-100" onClick={logout}>Cerrar sesión</button>
            <div className="text-center">
                <img src="/logo-santiago.png" className="logo-login-bottom" alt="Red Ciudad Joven" />
            </div>
        </div>
    );
};

export default SidebarNav;