import React, { useState, useEffect } from "react";
import customDataService from "../../../services/customDataService.js";

const ModalCustomField = ({ groupId, isModalOpen, onClose, editingModal, loadingContent, titleSingular }) => {

    const [formData, setFormData] = useState({
        field_type: "",
        custom_data_group_id: "",
        field_name: "",
        mandatory: true,
        option_list: [],
    });

    const [optionList, setOptionList] = useState([]);
    const [selectOption, setSelectOption] = useState("")

    useEffect(() => {
        if (editingModal) {
            setFormData({
                field_type: editingModal.field_type,
                custom_data_group_id: groupId,
                field_name: editingModal.field_name,
                mandatory: editingModal.mandatory,
                option_list: editingModal.option_list,
            });
        } else {
            setFormData({
                field_type: "",
                custom_data_group_id: "",
                field_name: "",
                mandatory: true,
                option_list: [],
            });
        }
    }, [editingModal]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const method = editingModal ? "PUT" : "POST";
        const payload = {
            ...formData,
        };

        if(payload.option_list.length > 0){
            payload.option_list = JSON.stringify(payload.option_list);
        } else {
            payload.option_list = "";
        }

        if (method === "PUT") {
            payload.id = editingModal.id
            await customDataService.updateCustomDataFields(payload);
            loadingContent()
            onClose()
        }

        if (method === "POST") {
            payload.custom_data_group_id = groupId;
            await customDataService.createCustomDataFields(payload);
            loadingContent()
            onClose()
        }
    };

    const handleChangeOption = (e) => {
        const { value } = e.target;
        setSelectOption(value);
    }

    const handleAddOption = () => {
        const updatedOptionList = [...optionList, selectOption];
        setOptionList(updatedOptionList);
        setFormData({
            ...formData,
            option_list: updatedOptionList,
        });
        setSelectOption("")
    }

    const handleRemoveOption = (formData, index) => {
        const option_list = formData.option_list;
        option_list.splice(index, 1);
        setFormData({
            ...formData,
            option_list: option_list,
        });
    }

    if (!isModalOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <h2 className="modal-title">
                    {editingModal ? `Editar ${titleSingular}` : `Crear ${titleSingular}`}
                </h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Nombre del {titleSingular}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="field_name"
                            value={formData.field_name}
                            onChange={handleChange}
                            required/>
                    </div>

                    <div className="form-group">
                        <label>Tipo</label>
                        <select
                            id="inputTypeSelect"
                            name="field_type"
                            value={formData.field_type}
                            onChange={handleChange}
                            className="form-select">

                            <option value="" disabled>
                                Selecciona una opción
                            </option>
                            <option value="number">Número</option>
                            <option value="text">Texto</option>
                            <option value="textarea">Area de texto</option>
                            <option value="email">Correo</option>
                            <option value="date">Fecha</option>
                            <option value="checkbox">Binario</option>
                            <option value="select">Selección simple</option>
                            <option value="select_multiple">Selección múltiple</option>
                            <option value="map_editor">Editor de Mapa</option>

                        </select>
                    </div>

                    {formData.field_type === 'select' || formData.field_type === 'select_multiple' ? (
                        <div className="form-group">
                            <label>Agregar opciones</label>
                            <div className="row">
                                <div className="col-sm-10">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectOption}
                                        onChange={handleChangeOption}/>
                                </div>
                                <div className="col-sm-2">
                                    <button className="btn btn-success" type="button" onClick={handleAddOption}>
                                        <i className="bi bi-plus-circle-fill"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <ul>
                                    {Array.isArray(formData.option_list) && formData.option_list.map((item, index) => (
                                        <li key={index} className="option-item-block">
                                            {item} <i className="btn-hover bi bi-x-circle" onClick={() => handleRemoveOption(formData, index)}></i>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ) : null }

                    <div className="form-group">
                        <fieldset>
                            <div className="form-check form-switch">
                                <input name="mandatory" className="form-check-input" type="checkbox" checked={formData.mandatory}
                                       onChange={(e) =>
                                           setFormData({
                                               ...formData,
                                               mandatory: e.target.checked,
                                           })
                                       }/>
                                <label className="form-check-label">Obligatorio</label>
                            </div>
                        </fieldset>
                    </div>

                    <button type="submit" className="btn btn-primary">
                        <i className="bi bi-save me-2"></i>
                        {editingModal ? "Guardar" : `Crear ${titleSingular}`}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary ms-2"
                        onClick={onClose}>
                        <i className="bi bi-x-circle me-2"></i>
                        Cancelar
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ModalCustomField;
