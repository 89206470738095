import React, { useState, useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import SidebarNav from "../Sidebar/SidebarNav.js";
import ModalUser from "./ModalUser.js";
import usersService from "../../../services/usersService.js";
import clientsService from "../../../services/clientsService.js";
import rolesService from "../../../services/rolesService.js";
import authService from "../../../services/authService.js";
import LoadingContent from "../../public/LoadingContent.js";

const Users = () => {

    const {clientId} = useParams();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [client, setClient] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getRoles();
        getUsers();
    }, [clientId]);

    const getRoles = async () => {
        rolesService.getRoles().then((response) => {
            setRoles(response);
        })
    }

    const getUsers = async () => {
        setLoading(true);
        if (clientId) {
            clientsService.getClient(clientId).then((data) => {
                if(data.success) {
                    setClient(data.data);
                }
            });
            usersService.getUsersByClientId(clientId).then((data) => {
                if(data.success) {
                    setUsers(data.data);
                }
                setLoading(false);
            });
        } else {
            usersService.getUsers().then(data => {
                if(data.success) {
                    setUsers(data.data);
                }
                setLoading(false);
            });
        }
    }

    const handleCloseSession = async () => {
        if(window.confirm("¿Seguro que quieres cerrar tu sesión?")){
            localStorage.removeItem('access_token');
            navigate('/login');
        }
    }

    const handleOpenModalForCreate = () => {
        setEditingUser(null);
        setIsModalOpen(true);
    };

    const handleEditUser = (user) => {
        setEditingUser(user);
        setIsModalOpen(true);
    };

    const handleDeleteUser = async (userId) => {
        if (window.confirm("¿Estás seguro que quieres eliminar este usuario?")) {
            usersService.deleteUser(userId).then((response) => {
                getUsers();
                setEditingUser(null);
                setIsModalOpen(false);
            });
        }
    };

    const handleResetPassword = (userId, email) => {
        if(window.confirm(`¿Seguro que deseas resetear la contraseña de este usuario?
Se enviará un correo a ${email} para su confirmación y autorización`)){
            authService.requestPasswordChange(userId, email).then((response) => {
                if (response.message) {
                    alert(response.message);
                }
            })
        }
    }

    return (
        <div className="container-fluid">
            <div className="row vh-100">
                {!clientId && (
                    <SidebarNav/>
                )}

                <div className={`p-4 content-container ${
                    clientId ? "col-md-12 col-lg-12": "col-md-9 col-lg-10"
                }`}>

                    {loading && (
                        <LoadingContent/>
                    )}

                    {!loading && (
                        <div>
                            <h3 className="mb-4">
                                <i className="bi bi-people-fill"></i>  Administración de Usuarios
                                {clientId && (
                                    <b> - {client.name}</b>
                                )}
                            </h3>

                            {clientId && (
                                <button className="btn btn-secondary mb-3"
                                        onClick={() => navigate(-1)}>
                                    <i className="bi bi-arrow-left"></i> Atrás
                                </button>
                            )}

                            <button className="btn btn-primary mb-3" onClick={handleOpenModalForCreate}>
                                Crear Nuevo Usuario
                            </button>
                            &nbsp;
                            <button className="btn btn-secondary mb-3" onClick={handleCloseSession}>
                                Cerrar Sesión
                            </button>

                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Usuario</th>
                                    <th>Roles</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {Array.isArray(users) && users.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.name}</td>
                                        <td>{user.mail}</td>
                                        <td>{user.username}</td>
                                        <td>{Array.isArray(user.roles) && user.roles.map((role) => role.name).join(", ")}</td>
                                        <td>
                                            <div className="btn-group" role="group">
                                                <button
                                                    className="btn btn-success btn-sm"
                                                    onClick={() => handleEditUser(user)}>
                                                    <i className="bi bi-pencil-square"></i>
                                                </button>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => handleResetPassword(user.id, user.mail)}>
                                                    <i className="bi bi-key"></i>
                                                </button>
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => handleDeleteUser(user.id)}>
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {isModalOpen && (
                        <ModalUser
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            editingUser={editingUser}
                            clientId={clientId}
                            getUsers={() => getUsers()}
                            roles={roles}
                        />
                    )}

                </div>

            </div>
        </div>
    );
}

export default Users;
