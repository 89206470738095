import commonApiService from './commonApiService.js';

const getProjects = async () => {
    try {
        const response = await commonApiService.apiPrivate().get('/projects');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al registrar');
    }
};

const setProjects = async (id, data) => {
    try {
        if(data.deadline === "null"){
            data.deadline = null;
        }
        return await commonApiService.apiPrivate().put(`/projects/${id}`, data);
    } catch (error) {
        throw new Error(error.response?.data || 'Error al registrar');
    }
};

const createProject = async (data) => {
    try {
        return await commonApiService.apiPrivate().post(`/projects`, data);
    } catch (error) {
        throw new Error(error.response?.data || 'Error al registrar');
    }
};

const deleteProject = async (id) => {
    try {
        return await commonApiService.apiPrivate().delete(`/projects/${id}`);
    } catch (error) {
        throw new Error(error.response?.data || 'Error al eliminar');
    }
};

const projectsService = {
    getProjects,
    setProjects,
    createProject,
    deleteProject,
};

export default projectsService;