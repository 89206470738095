import React from 'react';
import { useAuth } from '../AuthContext.js';
import "../pages/private/private.css";
import Loading from "../pages/public/Loading.js";
import {Navigate} from "react-router-dom";

function PrivateRoute({ children }) {
    const { isLoading, isAuthenticated } = useAuth();

    if (isLoading) {
        return <Loading />;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
