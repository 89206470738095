import React, { useState, useEffect } from "react";
import projectsService from "../../../services/projectsService.js";
import utilsHelper from "../../../helpers/utilsHelper.js";
import ModalProject from "./ModalProjects.js";
import SidebarNav from "../Sidebar/SidebarNav.js";
import LoadingContent from "../../public/LoadingContent.js";
import {useNavigate} from "react-router-dom";

const Projects = () => {

    const titleSingular = 'Programa';
    const titlePlural = 'Programas';
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingModal, setEditingModal] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const loadContent = () => {
        setLoading(true);
        projectsService.getProjects().then(data => {
            setLoading(false);
            setData(data);
        });
    }

    useEffect(() => {
        loadContent();
    }, []);

    const handleOpenModalForCreate = () => {
        setEditingModal(null);
        setIsModalOpen(true);
    };
    const handleOpenModalForEdit = (data) => {
        setEditingModal(data);
        setIsModalOpen(true);
    };
    const handleRedirectToTask = (id) => {
        navigate(`/actividades/${id}`);
    }
    const handleDelete = (id) => {
        if(window.confirm('¿Estás seguro que deseas eliminar este programa?')){
            setLoading(true);
            projectsService.deleteProject(id).then(data => {
                console.log(data);
                loadContent();
            });
        }
    };

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                <SidebarNav/>

                <div className="p-4 content-container col-md-9 col-lg-10">

                    {loading && (
                        <LoadingContent/>
                    )}

                    {!loading && (
                        <div>
                            <h3 className="mb-4">
                                <i className="bi bi-briefcase"></i> Administración de {titlePlural}
                            </h3>
                            <button className="btn btn-primary mb-3" onClick={handleOpenModalForCreate}>
                                Crear Nuevo {titleSingular}
                            </button>

                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Descripción</th>
                                    <th>Fecha de inicio</th>
                                    <th>Fecha de término</th>
                                    <th>Fecha de creación</th>
                                    <th>Fecha de actualización</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {Array.isArray(data) && data.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.project_name}</td>
                                        <td>{item.description}</td>
                                        <td>{utilsHelper.formatDate(item.started_at)}</td>
                                        <td>{utilsHelper.formatDate(item.deadline)}</td>
                                        <td>{utilsHelper.formatDate(item.created_at, true)}</td>
                                        <td>{utilsHelper.formatDate(item.updated_at, true)}</td>
                                        <td>
                                            <div className="btn-group">
                                                <button
                                                    className="btn btn-success btn-sm"
                                                    onClick={() => handleRedirectToTask(item.id)}>
                                                    <i className="bi bi-list-task"></i>
                                                    Tareas
                                                </button>
                                                <button
                                                    className="btn btn-success btn-sm"
                                                    onClick={() => handleOpenModalForEdit(item)}>
                                                    <i className="bi bi-pencil"></i>
                                                    Editar
                                                </button>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => handleDelete(item.id)}>
                                                    <i className="bi bi-trash"></i>
                                                    Borrar
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                {isModalOpen && (
                    <ModalProject
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        editingModal={editingModal}
                        loadContent={loadContent}
                        titleSingular={titleSingular}
                    />
                )}
            </div>
        </div>
</div>
);
}

export default Projects;