import commonApiService from "./commonApiService.js";
import {getTokenHeader} from "../helpers/accessTokenHelper.js";

const getActivities = async (projectId) => {
    try {
        let params = "";
        if(typeof projectId !== "undefined") {
            params = "?project_id=" + projectId;
        }
        const response = await commonApiService.apiPrivate().get(`/tasks${params}`, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al obtener clientes');
    }
};

const getActivity = async (taskId) => {
    try {
        const response = await commonApiService.apiPrivate().get(`/tasks/${taskId}`, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al obtener clientes');
    }
};

const updateActivity = async (taskId, payload) => {
    try {
        const response = await commonApiService.apiPrivate().put(`/tasks/${taskId}`, payload, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al intentar actualizar la actividad');
    }
};

const addUserToActivity = async (taskId, userId) => {
    try {
        const response = await commonApiService.apiPrivate().post(`/tasks/${taskId}/assign-users`, [{
            "user_id": userId
        }], getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al intentar asignar el usuario a la actividad');
    }
}

const removeUserToActivity = async (taskId, userId) => {
    try {
        const response = await commonApiService.apiPrivate().delete(`/tasks/${taskId}/assign-users/${userId}`, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al intentar remover el usuario de la actividad');
    }
}

const createActivity = async (data) => {
    try {
        const response = await commonApiService.apiPrivate().post(`/tasks`, data, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al intentar crear la actividad');
    }
}

const deleteActivity = async (taskId) => {
    try {
        const response = await commonApiService.apiPrivate().delete(`/tasks/${taskId}`, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al intentar eliminar la actividad');
    }
};

const addTaskWorkHours = async (payload) => {
    try {
        const response = await commonApiService.apiPrivate().post(`/tasks-work-hours`, payload, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al intentar actualizar la actividad');
    }
};

const getTaskWorkHours = async (task_id) => {
    try {
        const response = await commonApiService.apiPrivate().get(`/tasks-work-hours/${task_id}`, getTokenHeader());
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al intentar obtener la información');
    }
};

const activitiesService = {
    getActivities,
    getActivity,
    updateActivity,
    addUserToActivity,
    removeUserToActivity,
    createActivity,
    deleteActivity,
    addTaskWorkHours,
    getTaskWorkHours,
}

export default activitiesService;