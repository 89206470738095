import React, {useEffect, useState} from 'react';
import LoadingContent from "./LoadingContent.js";
import ErrorContent from "./Error.js";
import {useParams} from "react-router-dom";
import {isTokenExpired} from "../../helpers/accessTokenHelper.js";
import authService from "../../services/authService.js";
import SuccessContent from "./Success.js";

function Recovery() {

    const {token} = useParams();
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [sectionVisible, setSectionVisible] = useState('success');
    const errorDefaultMessage = 'La página que acabas de acceder es inválida o está expirada';
    const [errorMessage, setErrorMessage] = useState(errorDefaultMessage);
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(() => {
        if (isTokenExpired(token)) {
            setErrorMessage(errorDefaultMessage);
            setSectionVisible('error');
        } else {
            setSectionVisible('form');
        }
    }, [token]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if(password.length < 6) {
            setWarningMessage("La contraseña ingresada debe tener mínimo 6 caracteres");
        } else if(password === cpassword) {
            setSectionVisible('loading');
            authService.changePassword(token, password).then(data => {
                if (data.success){
                    setSectionVisible('success');
                } else {
                    setSectionVisible('error')
                    setErrorMessage(data.error);
                }
            });
        } else {
            setWarningMessage("Las contraseñas ingresadas no coinciden");
        }
    };

    return (
        <div>
            {sectionVisible === 'form' && (
                <div className="login-container d-flex align-items-center justify-content-center">
                    <div className="card shadow-lg p-4">
                        <div className="card-body">
                            <img src="/logo-rcj.png" className="logo-login-top" alt="Red Ciudad Joven"/>
                            <h3 className="text-center mb-4">Actualización de contraseña</h3>
                            <p className="text-center text-muted mb-4">
                                Ingresa tu nueva contraseña y confirmala para actualizar tu cuenta
                            </p>

                            {warningMessage !== '' && (
                                <div className="alert alert-dismissible alert-warning"
                                     style={{marginTop: '10px', marginBottom: '10px'}}>
                                    <h4 className="alert-heading">Error</h4>
                                    <p className="mb-0">{warningMessage}</p>
                                </div>
                            )}

                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label htmlFor="user" className="form-label">
                                        Nueva contraseña
                                    </label>
                                    <input
                                        type="password"
                                        id="user"
                                        className="form-control"
                                        placeholder="Ingresa tu nueva contraseña aquí"
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="cpassword" className="form-label">
                                        Confirma contraseña
                                    </label>
                                    <input
                                        type="password"
                                        id="cpassword"
                                        className="form-control"
                                        placeholder="Confirma tu nueva contraseña aquí"
                                        onChange={(e) => setCPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button type="submit" className="btn btn-success w-100">
                                        Actualizar contraseña
                                    </button>
                                </div>
                            </form>

                            <div className="text-center">
                                <img src="/logo-santiago.png" className="logo-login-bottom" alt="Red Ciudad Joven"/>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {sectionVisible === 'loading' && (
                <LoadingContent/>
            )}

            {sectionVisible === 'error' && (
                <ErrorContent
                    errorMessage={errorMessage} />
            )}

            {sectionVisible === 'success' && (
                <SuccessContent
                    title="¡Éxito!"
                    message="Tu contraseña ha sido actualizada correctamente."/>
            )}
        </div>
    );
}

export default Recovery;