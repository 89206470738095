import React from "react";
import utilsHelper from "../../../helpers/utilsHelper.js";

const TasksPartialRowItems = ({idented, item, isExpired, toggleExpand, expandedTasks, goToActivity, handleOpenModalForEdit, handleDelete}) => {

    return (
        <>
            <tr
                className={`${idented ? 'indented-row' : ''} activity-item`}
                key={item.id}>

                <td className={`${isExpired ? 'expired-row' : ''}`}>
                    {item.subtasks && item.subtasks.length > 0 ? (
                        <div>
                            <button
                                className="expand-btn"
                                onClick={() => toggleExpand(item.id)}
                            >
                                {expandedTasks[item.id] ? "−" : "+"}
                            </button>
                        </div>
                    ):null}
                </td>

                <td className={`${isExpired ? 'expired-row' : ''}`} onClick={() => goToActivity(item.id)}>
                    {utilsHelper.formatDate(item.created_at, false)}
                </td>

                <td className={`${isExpired ? 'expired-row' : ''}`} onClick={() => goToActivity(item.id)}>
                    {item.name}
                </td>

                <td className={`${isExpired ? 'expired-row' : ''}`} onClick={() => goToActivity(item.id)}>
                    {item.status_id === null
                        ? <span className="badge bg-secondary">Sin estado</span>
                        : <span className="badge" style={{backgroundColor: item.status?.color}}>
                                                            {item.status?.name}
                                                          </span>}
                </td>

                <td className={`${isExpired ? 'expired-row' : ''}`} onClick={() => goToActivity(item.id)}>
                    {utilsHelper.formatDate(item.estimated_start)}
                </td>

                <td className={`${isExpired ? 'expired-row' : ''}`} onClick={() => goToActivity(item.id)}>
                    {utilsHelper.formatDate(item.estimated_end)}
                </td>

                <td className={`${isExpired ? 'expired-row' : ''}`} onClick={() => goToActivity(item.id)}>
                    <div title={item.created_by_user?.name}
                         className="profile-avatar profile-avatar-asignado profile-avatar-table">
                        {utilsHelper.shortenName(item.created_by_user?.name)}
                    </div>
                </td>

                <td className={`${isExpired ? 'expired-row' : ''}`} onClick={() => goToActivity(item.id)}>
                    <table>
                        <tbody>
                        <tr>
                            {Array.isArray(item.task_assigns) && item.task_assigns.map((task_assign) => (
                                <td key={task_assign.id}>
                                    <div title={task_assign.name}
                                         className="profile-avatar profile-avatar-reportado profile-avatar-table">
                                        {utilsHelper.shortenName(task_assign.name)}
                                    </div>
                                </td>
                            ))}
                        </tr>
                        </tbody>
                    </table>
                </td>

                <td className={`${isExpired ? 'expired-row' : ''}`}>
                    <div className="btn-group">
                        <button
                            type="button"
                            className="btn btn-success btn-sm"
                            onClick={() => handleOpenModalForEdit(item)}>
                            <i className="bi bi-pencil"></i>
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(item.id)}>
                            <i className="bi bi-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>

            {item.subtasks && item.subtasks.length > 0 && expandedTasks[item.id] ? (
                <>
                    {item.subtasks.map((subtask) => (
                        <TasksPartialRowItems
                            idented={true}
                            item={subtask}
                            isExpired={isExpired}
                            toggleExpand={toggleExpand}
                            expandedTasks={expandedTasks}
                            goToActivity={goToActivity}
                            handleOpenModalForEdit={handleOpenModalForEdit}
                            handleDelete={handleDelete}
                        />
                    ))}
                </>
            ): null}

        </>
    );
}

export default TasksPartialRowItems;